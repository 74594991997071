import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';
import OurworkList from 'components/ourwork-list';

const OurworkListContainer = ({ items }) => (
  <Consumer>
    {({ filterCaseStudies }) => (
      <OurworkList items={items} filterCaseStudies={filterCaseStudies} />
    )}
  </Consumer>
);

OurworkListContainer.propTypes = {
  items: PropTypes.array.isRequired,
};

export default OurworkListContainer;
