import nanostyled from 'nanostyled';

export const Background = nanostyled('section', {
  base: 'w-full pt-2 pb-16 sm:py-16 md:py-32',
  bg: '',
});

export const Container = nanostyled('div', {
  base: 'w-full max-w-6xl',
  margin: 'mx-auto',
});

export const Type = nanostyled('div', {
  base: 'typography white',
});

export const Flex = nanostyled('div', {
  base: 'flex',
  flex: '',
  justify: '',
  margin: '',
});

export const Number = nanostyled('div', {
  base:
    'text-7xl sm:text-8xl md:text-7xl lg:text-8xl xl:text-9xl text-white font-extralight text-center',
});

export const Description = nanostyled('h4', {
  base: 'mt-4 text-center leading-tight',
});

export const InfoContainer = nanostyled('div', {
  base: 'max-w-4xl mx-auto',
});

export const Margin = nanostyled('div', {
  base: '',
  margin: 'mx-4 xs:mx-10 sm:mx-20',
});
