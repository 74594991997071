import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduce, includes } from 'lodash';
import Dropdown from 'react-dropdown';

import { Center, Container, StyledButton } from './ourwork-filter.css';
import 'react-dropdown/style.css';

const OurworkFilter = ({
  items,
  filterCaseStudies,
  changeFilterCaseStudies,
}) => {
  const options = reduce(
    items,
    function(carry, { categories }) {
        categories &&
        categories.map(t => {
          !includes(carry, t) && carry.push(t);
        });
      return carry;
    },
    []
  );
  const finalOptions = ['All Services', ...options];
  let defaultOption = finalOptions[0];
  // if (typeof window !== 'undefined') {
  //   const querystring = window.location.search;
  //   const params = new URLSearchParams(querystring);
  //   const serviceFromParams = params.get('service');
  //   if (finalOptions.includes(serviceFromParams)) {
  //     defaultOption = serviceFromParams;
  //     // changeFilterCaseStudies(serviceFromParams);
  //   }
  // }

  useEffect(() => {
    if (defaultOption === null || defaultOption === 'All Services') {
      changeFilterCaseStudies(null);
    } else {
      changeFilterCaseStudies(defaultOption);
    }
  }, [changeFilterCaseStudies, defaultOption]);

  const onChange = ({ value }) => {
    if (value === null || value === 'All Services') {
      changeFilterCaseStudies(null);
    } else {
      changeFilterCaseStudies(value);
    }
  };

  return (
    <Center>
      <Container>
        <Dropdown
          className="!shadow-none !border-none blue"
          controlClassName=""
          menuClassName="!shadow-none !border-none"
          options={finalOptions}
          onChange={onChange}
          value={defaultOption}
          placeholder="Select an option"
        />
        {/* <StyledButton
        key={'all'}
        onClick={() => changeFilterCaseStudies(null)}
        active={
          filterCaseStudies == null
            ? 'text-brand-blue border-b-2 border-solid border-brand-blue'
            : 'border-b-2 border-solid border-transparent'
        }
      >
        All
      </StyledButton>
      {reduce(
        items,
        function(carry, item) {
          item.categories &&
            item.categories.map(
              category => !includes(carry, category) && carry.push(category)
            );
          return carry;
        },
        []
      ).map(category => (
        <StyledButton
          key={category}
          onClick={() => changeFilterCaseStudies(category)}
          active={
            filterCaseStudies == category
              ? 'text-brand-blue border-b-2 border-solid border-brand-blue'
              : 'border-b-2 border-solid border-transparent'
          }
        >
          {category}
        </StyledButton>
      ))} */}
      </Container>
    </Center>
  );
};

OurworkFilter.propTypes = {
  items: PropTypes.array.isRequired,
  filterCaseStudies: PropTypes.string,
  changeFilterCaseStudies: PropTypes.func,
};

export default OurworkFilter;
