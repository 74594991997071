import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';
import OurworkFilter from 'components/ourwork-filter';

const OurworkFilterContainer = ({ items }) => (
  <Consumer>
    {({ filterCaseStudies, changeFilterCaseStudies }) => (
      <OurworkFilter
        items={items}
        filterCaseStudies={filterCaseStudies}
        changeFilterCaseStudies={changeFilterCaseStudies}
      />
    )}
  </Consumer>
);

OurworkFilterContainer.propTypes = {
  items: PropTypes.array.isRequired,
};

export default OurworkFilterContainer;
