import React from 'react';
import PropTypes from 'prop-types';

import MdMap from 'images/md-map.svg';
import Button from 'components/blocks/button';
import IOMenu from 'containers/io-menu';

import {
  Container,
  Background,
  Flex,
  Type,
  Number,
  Description,
  InfoContainer,
  Margin,
} from './case-study-map.css';

const mapUrl =
  'https://ndc-md-org.maps.arcgis.com/apps/Embed/index.html?webmap=cfd398a1c5a64435a01f200c6a035e0a&extent=-78.7892,38.1107,-74.7325,39.8759&zoom=true&previewImage=false&scale=true&disable_scroll=true&theme=light';

const CaseStudyMap = ({ pageColor }) => (
  <IOMenu withImage={true}>
    <Background bg={`bg-brand-${pageColor}`}>
      <Margin>
        <Container>
          <Type>
            <h2 className="text-center">Our Impact</h2>
            <Flex
              margin="my-16 md:my-20 lg:my-32 md:-mx-8"
              flex="flex-col md:flex-row items-center md:items-stretch"
            >
              <div className="md:w-1/3 md:max-w-sm pb-10 md:pb-0 mx-4 md:mx-0 px-4 xs:px-8 sm:px-16 md:pl-0 md:pr-8 lg:pr-16">
                <Number>92%</Number>
                <Description>
                  of NDC partners said our process strengthened their community
                  association
                </Description>
              </div>
              <div className="md:w-1/3 max-w-sm border-solid border-white border-t-2 border-b-2 md:border-t-0 md:border-b-0 md:border-l-2 md:border-r-2 py-10 md:py-0 mx-4 md:mx-0 px-4 xs:px-8 md:px-8 lg:px-16">
                <Number>5k</Number>
                <Description>
                  {
                    // eslint-disable-next-line
                    'we design over 5,000 new tree plantings each year'
                  }
                </Description>
              </div>
              <div className="md:w-1/3 max-w-sm pt-10 md:pt-0 mx-4 md:mx-0 px-4 xs:px-8 sm:px-16 md:pr-0 md:pl-8 lg:pl-16">
                <Number>200k</Number>
                <Description>
                  each year our volunteers invest more than $200,000 worth of
                  hours
                </Description>
              </div>
            </Flex>
          </Type>
          <InfoContainer>
            <a href={mapUrl} target="_blank" rel="noopener noreferrer">
              <MdMap />
            </a>
            <div className="mt-8">
              <Type>
                <p>Click through to view GIS map of historic NDC projects.</p>
                <h3>
                  Since 1968, NDC has worked on over 4,000 projects in the state
                  of Maryland.
                </h3>
              </Type>
            </div>
            <div className="text-center mt-8 sm:mt-16">
              <Button
                text="Explore the Map"
                link={mapUrl}
                isInternal={false}
                opensNewTab={true}
                isInline
              />
              <Button
                text="See More Projects"
                link="/projects"
                isInternal={true}
                isInline
              />
            </div>
          </InfoContainer>
        </Container>
      </Margin>
    </Background>
  </IOMenu>
);

CaseStudyMap.propTypes = {
  pageColor: PropTypes.string,
};

export default CaseStudyMap;
