import nanostyled from 'nanostyled';

export const Center = nanostyled('div', {
  base: 'mx-auto  max-w-8xl p-no-space',
});
export const Container = nanostyled('section', {
  base: 'flex flex-wrap xs:mx-6 mb-4',
});

export const StyledButton = nanostyled('button', {
  base:
    'text-sm sm:text-base font-extrabold tracking-wide uppercase mx-4 mb-4 pb-1',
  active: '',
});
